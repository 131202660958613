const burger = document.querySelector('.burger')
const nav = document.querySelector('.nav')
const offer = document.querySelector('.offer')
const offerList = document.querySelector('.offer__list')
burger.addEventListener('click', () => {
    nav.classList.toggle('header__nav--active')
    burger.classList.toggle('burger--active')
})
if (window.screen.availWidth < 1300)
    offer.addEventListener('click', () => {
        offer.classList.toggle('offer--active')
    })
